<template>
  <div class="tinymce-editor">
    <div v-if="!value" :style="{ zIndex }" class="tinymce-placeholder" @click="placeholderClick">{{ placeholder }}</div>
    <editor
      ref="editor"
      v-if="!reloading"
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onBlur="onBlur"
      @onClick="onClick"
    >
    </editor>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/fullscreen'
import 'tinymce/icons/default'
import { uploadAction, getFileAccessHttpUrl } from '@/api/manage'
import { getVmParentByName } from '@/utils/util'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
var postValue = null
var clearValue = null
export default {
  mixins: [UploadFileToOssMixins],
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      required: false
    },
    triggerChange: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '50px'
    },
    placeholder: {
      type: String,
      default: 'ALT+回车换行，回车提交'
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount fullscreen'
    },
    toolbar: {
      type: [String, Array],
      default:
        'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists unlink image media table | removeformat | fullscreen',
      branding: false
    }
  },
  data() {
    return {
      //初始化配置
      init: {
        selector: 'textarea',
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/lightgray',
        image_dimensions: '800x600',
        height: 41,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        statusbar: false,
        menubar: false,
        toolbar_drawer: false,
        setup(editor) {
          // 添加👇
          editor.on('keydown', e => {
            if (e.keyCode === 13 && !e.altKey) {
              e.preventDefault()
              postValue()
            } else if (e.keyCode === 13 && e.altKey) {
              e.preventDefault()
              editor.insertContent('<p style="margin-top: 0px; margin-bottom: 0px;">&nbsp;</p>')
            } else if (e.key == 'Backspace' || e.key == 'Delete') {
              if (!editor.getContent({ format: 'text' })) {
                e.preventDefault()
              }
            }
          })
          editor.on('paste', e => {
            // 处理粘贴事件
            setTimeout(() => {
              let text = editor.getContent()
              if (text.indexOf('image') == -1){
                text = editor.getContent({ format: 'text' })
                let newText = ''
                text.split('\n')?.forEach(t => {
                  newText = newText + `<p style="margin-top: 0px; margin-bottom: 0px;">${t}</p>`
                })
                clearValue(newText || text, editor)
                /* editor.setContent(newText||text); */
              }
            }, 0)
          })
        },
        urlconverter_callback: (url, node, onSave, name) => {
          if (node === 'img' && url.startsWith('blob:')) {
            tinymce.activeEditor && tinymce.activeEditor.uploadImages()
          }
          return url
        },
        images_upload_handler: async (blobInfo, success, failure, progress) => {
          let file = blobInfo.blob()
          if (!file.name) file.name = 'copy' + new Date().getTime() + '.jpg'
          await this.beforeUpload(file)
          const token = Vue.ls.get(ACCESS_TOKEN)
          let formData = new FormData()
          this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
          for (let key in this.ossUploadData) {
            formData.append(key, this.ossUploadData[key])
          }
          formData.append('file', file)
          axios
            .post(this.ossUploadUrl, formData, {
              'Content-Type': 'multipart/form-data;',
              headers: {
                'X-Access-Token': token
              }
            })
            .then(() => {
              let params = this.getSaveFileParams(file)
              console.log(params.filePath)
              success(params.filePath)
            })
        }
      },
      myValue: this.value,
      reloading: false,
      zIndex: 1
    }
  },
  mounted() {
    this.initATabsChangeAutoReload()
    postValue = this.postValue
  },
  methods: {
    clearValue(text, editor) {
      this.myValue = text
      let iframs = []
      let par = document.getElementsByClassName('show-img')[0]
      Array.from(par.getElementsByClassName('tinymce-editor')).forEach(d => {
        iframs = [...iframs, ...Array.from(d.getElementsByClassName('tox-edit-area__iframe'))]
      })
      if (!iframs.length) {
        return
      }
      Array.from(iframs).forEach(ifram => {
        if(!ifram.contentWindow.document.getElementsByClassName('ephox-snooker-resizer-bar')[0]) return
        let parent = ifram.contentWindow.document.getElementsByClassName('ephox-snooker-resizer-bar')[0].parentNode
        let rows = ifram.contentWindow.document.getElementsByClassName('ephox-snooker-resizer-bar')
        if (rows) {
          Array.from(rows).forEach(d => {
            parent.removeChild(d)
          })
        }
      })
      this.$nextTick(() => {
        editor.selection.select(editor.getBody(), true)
        editor.selection.collapse(false)
      })
    },
    onBlur() {
      this.$emit('inputBlur')
    },
    postValue() {
      this.$emit('postValue')
    },
    placeholderClick(e) {
      this.zIndex = 0
      let ifram = document.getElementsByClassName('tinymce-editor')[0].querySelector('iframe')
      ifram.contentWindow.document.getElementById('tinymce').focus()
    },
    EditorOnsize() {
      let iframs = []
      let pars = Array.from(document.getElementsByClassName('drag-content-show'))
      pars.forEach(c => {
        Array.from(c.getElementsByClassName('tinymce-editor')).forEach(d => {
          iframs = [...iframs, ...Array.from(d.getElementsByClassName('tox-edit-area__iframe'))]
        })
      })
      if (!iframs.length) return
      Array.from(iframs).forEach(ifram => {
        let iframeBody = ifram.contentWindow.document.getElementsByClassName('mce-content-body')[0]
        Array.from(iframeBody.getElementsByTagName('P')).forEach(p => {
          p.style.marginTop = 0
          p.style.marginBottom = 0
        })
        iframeBody.margin = 0
        ifram.style.height = iframeBody.offsetHeight + 20 + 'px !important'
        pars.forEach(par => {
          if(par.getElementsByClassName('tox-tinymce')[0]&&par.getElementsByClassName('tox-tinymce')[0].style){
            par.getElementsByClassName('tox-tinymce')[0].style.height = iframeBody.offsetHeight + 20 + 'px'
          }
        })
      })
    },
    imgUrlToFile(img, callback) {
      let fileName = img.split('/').pop()
      let type = fileName.split('.').pop()
      this.dataURLtoBlob(img, function(blobData) {
        var file = new File([blobData], fileName, { type: 'image/' + type })
        callback(file)
      })
    },
    dataURLtoBlob(dataurl, callback) {
      let xhr = new XMLHttpRequest()
      xhr.open('get', dataurl, true)
      xhr.responseType = 'blob'
      xhr.onload = function(res) {
        if (this.status == 200) {
          var blob = this.response
          callback(blob)
        }
      }
      xhr.send()
    },
    reload() {
      this.reloading = true
      this.$nextTick(() => (this.reloading = false))
    },

    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },

    //可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    },

    /**
     * 自动判断父级是否是 <a-tabs/> 组件，然后添加事件监听，自动触发reload()
     *
     * 由于 tabs 组件切换会导致 tinymce 无法输入，
     * 只有重新加载才能使用（无论是vue版的还是jQuery版tinymce都有这个通病）
     */
    initATabsChangeAutoReload() {
      // 获取父级
      let tabs = getVmParentByName(this, 'ATabs')
      let tabPane = getVmParentByName(this, 'ATabPane')
      if (tabs && tabPane) {
        // 用户自定义的 key
        let currentKey = tabPane.$vnode.key
        // 添加事件监听
        tabs.$on('change', key => {
          // 切换到自己时执行reload
          if (currentKey === key) {
            this.reload()
          }
        })
      } else {
        //update--begin--autor:wangshuai-----date:20200724------for：富文本编辑器切换tab无法修改------
        let tabLayout = getVmParentByName(this, 'TabLayout')
        tabLayout.excuteCallback(() => {
          this.reload()
        })
        //update--begin--autor:wangshuai-----date:20200724------for：文本编辑器切换tab无法修改------
      }
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.zIndex = 1
      } else {
        this.zIndex = 0
      }
      this.myValue = newValue == null ? '' : newValue
      let pars = Array.from(document.getElementsByClassName('drag-content-show'))
      pars.forEach(par => {
        if (
          par.getElementsByClassName('tox-edit-area__iframe')[0]?.contentWindow.document.getElementsByTagName('img')
            .length
        ) {
          Array.from(
            par.getElementsByClassName('tox-edit-area__iframe')[0]?.contentWindow.document.getElementsByTagName('img')
          ).forEach(item => {
            item.style.maxWidth = this.maxWidth
          })
        }
      })
    },
    myValue(newValue) {
      if (this.triggerChange) {
        this.$emit('change', newValue)
      } else {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .tox-edit-area {
  img {
    width: 50px !important;
    height: auto !important;
  }
  border: none !important;
}
/deep/ .tox-tinymce {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px;
}
/deep/ iframe {
  min-height: 60px !important;
}
.tinymce-editor {
  position: relative;
}
.tinymce-placeholder {
  position: absolute;
  top: 10px;
  left: 11px;
  color: #bfbfbf;
  font-size: 14px;
}
</style>
